import React from 'react';

import Icon from './Icon';
import Icons from './icons.json';

const ListIcon = () => {
  return(
    <div>
    {Icons.map((icon) =>
      <Icon key={icon.name} icon={icon} iconType={icon.name}/>
    )}
    </div>
  )

};

export default ListIcon;
