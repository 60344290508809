import React, {useState, useEffect} from 'react';

import "../assets/style/less/icon.css"
import sprite from '../assets/icons/sprite.svg';


const Icon = (icon, storyType) => {

  const ref = React.useRef();

  const [size, setSize] = useState([
    {'size': 16, 'enable' : false, 'visu' : false},
    {'size': 24, 'enable' : false, 'visu' : false},
    {'size': 32, 'enable' : false, 'visu' : false},
    {'size': 48, 'enable' : false, 'visu' : false},
    {'size': 64, 'enable' : false, 'visu' : false}
  ]);
  const [listSize, setListSize] = useState([]);
  const [listImg, setListImg] = useState([]);
  const [img, setImg] = useState();
  const [sizeChoice, setSizeChoice] = useState();
  const [name, setName] = useState();

  function changeSize(index, value) {
    for(var i = 0; i < size.length; i++) {
      if(size[i].visu) {
        size[i].visu = false;
      } else if(i == index) {
        size[i].visu = true;
      }
    }
    listImg.map((img) => {
      var imgSplit = img.split('_');
      var lengthImg = imgSplit.length;
      if(imgSplit[lengthImg-1] == value+"px") {
        setSizeChoice(value);
        setImg(img);
      }
    })
  }

  function verifSize(number) {
    for(var i = 0; i < size.length; i++) {
       if(size[i].size === number) {
         size.map((index) => {
           if(index.size === number) {
             index.enable = true;
           }
         })
       }
    }
  }

  useEffect(() => {
    for (const property in icon.icon) {
      switch(property) {
        case "16px":
          verifSize(16);
          setListSize(oldArray => [...oldArray, "16"]);
          setListImg(oldArrayImg => [...oldArrayImg, icon.icon[property]]);
          break;
        case "24px":
          verifSize(24);
          setListSize(oldArray => [...oldArray, "24"]);
          setListImg(oldArrayImg => [...oldArrayImg, icon.icon[property]]);
          break;
        case "32px":
          verifSize(32);
          setListSize(oldArray => [...oldArray, "32"]);
          setListImg(oldArrayImg => [...oldArrayImg, icon.icon[property]]);
          break;
        case "48px":
          verifSize(48);
          setListSize(oldArray => [...oldArray, "48"]);
          setListImg(oldArrayImg => [...oldArrayImg, icon.icon[property]]);
          break;
        case "64px":
          verifSize(64);
          setListSize(oldArray => [...oldArray, "64"]);
          setListImg(oldArrayImg => [...oldArrayImg, icon.icon[property]]);
          break;
      }
    }
  }, []);

  useEffect(() => {
    if(img === undefined) {
      setImg(listImg[0]);
    }
  });

  useEffect(() => {
    for(var i = 0; i < size.length; i++) {
      if(size[i].enable) {
        setSizeChoice(size[i].size);
        size[i].visu = true;
        break;
      }
    }
  }, [])

  return(
    <div style={{display: 'inline-block', marginRight: '10px', marginBottom: '10px'}}>
      <div className="iconPresentation">
        <p style={{marginLeft: '10px'}}>{icon.icon.name}</p>
        <svg className="icon"><use xlinkHref={sprite + "_" + img} /></svg>
        <div className="iconWidth">
          {
            size.map((s, index) =>
              s.enable
               ? <span className="infoWidthIcon infoAccess" style={{'backgroundColor': s.visu ? 'yellow' : 'white'}} key={s.size} onClick={() => changeSize(index, s.size)}>{s.size} {s.visu}</span>
               : <span className="infoWidthIcon" key={s.size} style={{'backgroundColor' : 'grey'}}>{s.size}</span>
            )
          }
        </div>
        <div className="iconUtil">
          <div className="iconDl">
            <a href={"../assets/icons/"+sizeChoice+"px/"+img+".svg"} download><img src="../assets/img/logo_download.png" style={{height: '30px', width: '40px'}}/></a>
          </div>
          <div style={{display: 'inline'}}>
            <img src="../assets/img/code_logo.png" className="codeLogo" onClick={() => {navigator.clipboard.writeText("<img className=\"icon\" src={"+img+"} key={"+img+"}/>")}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Icon;
